import React from "react";
import { Spin } from "antd";

function MySpin() {
  return (
    <div className="Myspin ">
      <Spin size="large" />
    </div>
  );
}

export default MySpin;
