import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginRes } from "../apis/auth/types";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: "",
    auth: null,
  } as loginRes,
  reducers: {
    setCurrentAuth: (state, { payload }: PayloadAction<loginRes | null>) => {
      state.auth = payload?.auth || null;
      state.token = payload?.token || "";
    },
  },
});

export default authSlice;
export const { setCurrentAuth } = authSlice.actions;
