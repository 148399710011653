import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MyBaseQueryFn, RootState } from "../..";
import { RemiseListRes,RemiseListReq } from "./type";
export const remiseApi = createApi({
    reducerPath: "remise-api",
    baseQuery: fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_API_URL!}remise`,
      prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token;
        if (token) headers.set("Authorization", `Bearer ${token}`);
        return headers;
      },
    }) as MyBaseQueryFn,
    tagTypes: ["Remise"],
    endpoints: (builder) => ({
      getAll: builder.query<RemiseListRes, void>({
        query: () => ({
          url: "/list",
          method: "GET",
        }),
        providesTags:[{ type: "Remise", id: "List" }]
      }),
      update: builder.mutation<RemiseListRes,RemiseListReq>({
          query:(body)=>({
            url:'/update',
            method:"PUT",
            body
          }),
          invalidatesTags: [{ type: "Remise", id: "List" }]
      }),
      create: builder.mutation<RemiseListRes,RemiseListReq>({
          query:(body)=>({
              url:'/add',
              body,
              method:"POST",
          }),
          invalidatesTags: [{ type: "Remise", id: "List" }]
      })
    }),
  });