import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MyBaseQueryFn, RootState } from "../..";
import {queryBuilder} from '../../../util/queryBuilder'
import {
  clientListRes,
  clientReq,
  clientRes,
  updateClientReq,
  updateClientRes,
  ClientSearch
} from "./types";

export const clientApi = createApi({
  reducerPath: "client-api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL!}client`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }) as MyBaseQueryFn,
  tagTypes: ["Client"],
  endpoints: (builder) => ({
    getAll: builder.query<clientListRes, ClientSearch>({
      query: (query) => ({
        url: `/listCLients${queryBuilder(query)}`,
        method: "GET",
      }),
      providesTags: [{ type: "Client", id: "List" }],
    }),
    getOneById: builder.query<clientRes, clientReq>({
      query: ({ id }) => ({
        url: `/fiche/${id}`,
        method: "GET",
      }),
    }),
    update: builder.mutation<updateClientRes, updateClientReq>({
      query: (body) => ({
        url: `/update`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "Client", id: "List" }],
    }),
  }),
});
