import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MyBaseQueryFn, RootState } from "../..";
import { relanceListRes,RelanceSearch } from "./types";
import {queryBuilder} from '../../../util/queryBuilder'
export const relanceApi = createApi({
  reducerPath: "relance-api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL!}relance`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }) as MyBaseQueryFn,
  tagTypes: ["Relance"],
  endpoints: (builder) => ({
    getAll: builder.query<relanceListRes, RelanceSearch>({
      query: (query) => ({
        url: `/list${queryBuilder(query)}`,
        method: "GET",
      }),
    }),
  }),
});
